<template>
  <v-container fluid>
    <v-card flat :class="$vuetify.breakpoint.mobile ? '' : 'pa-2'">  
      <v-form lazy-validation v-on:submit.prevent="saveUpdateForm" ref="form">    
        <v-row justify="center">
          <v-card width="100%" min-width="200" flat>
            <v-row no-gutters>
              <template v-for="(pergunta) in perguntas">
                <v-col
                  :cols="pergunta.config.cols" 
                  :md="pergunta.config.md"
                  :align-self="pergunta.config.align"
                  :offset="pergunta.config.offset"
                  :key="`${pergunta.ordem}_${pergunta.respostas}`"
                >
                  <component
                    :is="pergunta.tipoPergunta.nomeComponent"
                    :key="`${pergunta.ordem}_${pergunta.respostas}`"
                    v-bind="{ ...pergunta.props }"
                    @returValue="valorPergunta(pergunta, $event)"
                  ></component>
                </v-col>
              </template>
            </v-row>
            <v-card-actions v-if="!saveBottonOff">
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit"> Gravar </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>     
        <alerta></alerta>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import { createUpdateForm } from "@/utils/formUtils"

import RespostaCurtaCreateEdit from "@/components/forms/RespostaCurtaCreateEdit.vue";
import RespostaTextoCreateEdit from "@/components/forms/RespostaTextoCreateEdit.vue";
import RespostaVerdadeiroFalsoCreateEdit from "@/components/forms/RespostaVerdadeiroFalsoCreateEdit.vue";
import RespostaDataCreateEdit from "@/components/forms/RespostaDataCreateEdit.vue";
import RespostaUnicaCreateEdit from "@/components/forms/RespostaUnicaCreateEdit.vue";
import RespostaMultiplaCreateEdit from "@/components/forms/RespostaMultiplaCreateEdit.vue";
import RespostaAudioCreateEdit from "@/components/forms/RespostaAudioCreateEdit.vue";
import RespostaArquivoCreateEdit from "@/components/forms/RespostaArquivoCreateEdit.vue";
import RespostaImagemCreateEdit from "@/components/forms/RespostaImagemCreateEdit.vue";
import RespostaVideoCreateEdit from "@/components/forms/RespostaVideoCreateEdit.vue";
import RespostaSubtituloCreateEdit from "@/components/forms/RespostaSubtituloCreateEdit.vue";
import RespostaEditorCreateEdit from "@/components/forms/RespostaEditorCreateEdit.vue";
import RespostaLegendaCreateEdit from "@/components/forms/RespostaLegendaCreateEdit.vue";

export default {
  name: "formulario-create-edit",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    storageNameParam: {
      type: String,
      default: undefined,
    },
    domain: {
      type: String,
      default: undefined,
    },
    domainId: {
      type: Number,
      default: undefined,
    },
    formularioParam: {
      type: Object,
      default: undefined,
    },
    formularioRascunho: {
      type: Boolean,
      default: false,
    },
    saveBottonOff: {
      type: Boolean,
      default: false,
    },
    autoSaveUpdate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return { 
      storageName: null,
      filaChamadasStorage: [],
      formulario: {},
      respostasFormulario: new Map(),
      perguntas: [],
      localPerguntas: [],
      perguntasOriginais: [],
      isModeloForms: false,
      executeSaveUpdateForm: this.value,
      autoSaveUpdateTimeout: null,
    };
  },
  components: {
    Alerta,
    RespostaCurtaCreateEdit,
    RespostaTextoCreateEdit,
    RespostaVerdadeiroFalsoCreateEdit,
    RespostaDataCreateEdit,
    RespostaUnicaCreateEdit,
    RespostaMultiplaCreateEdit,
    RespostaAudioCreateEdit,
    RespostaArquivoCreateEdit,
    RespostaImagemCreateEdit,
    RespostaVideoCreateEdit,
    RespostaSubtituloCreateEdit,
    RespostaEditorCreateEdit,
    RespostaLegendaCreateEdit,
  },
  watch: {
    value(value) {
      this.executeSaveUpdateForm = value
    },
    executeSaveUpdateForm(value) {
      this.$emit('input', value)
      if (value) {
        this.saveUpdateForm()
      }
    },
  },
  computed: {
    ...mapGetters(["formularioQuery"]),
    tipoPerguntaMedia() {
      return ["VIDEO", "IMAGEM", "ARQUIVO", "AUDIO"]
    },
  },
  methods: {
    ...mapActions(["setUltimaTela", "setAlerta", "setFormularioQuery"]),
    getKeyPergunta(pergunta) {
      return `${pergunta.ordem}_${JSON.stringify(pergunta.respostas)}`
    },
    async saveUpdateForm() {
      const self = this
      let form = self.formulario

      // Realiza o controle da execução via parametro.
      if (this.executeSaveUpdateForm) {
        this.executeSaveUpdateForm = false
      }

      // Realiza o controle da execução via autoSaveUpdate
      if (this.autoSaveUpdate && this.autoSaveUpdateTimeout) {
        clearTimeout(this.autoSaveUpdateTimeout)
        this.autoSaveUpdateTimeout = null
      }

      // Validação de campos obrigatórios preenchidos
      if(!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos obrigatórios'
        })
        return
      }

      // Carrega as respostas no formulário
      await form.perguntas.forEach(pergunta => {
        pergunta.respostas = null;
        if (self.respostasFormulario.get(`${pergunta.ordem}`)) {
          let respostaObject = self.respostasFormulario.get(`${pergunta.ordem}`).resposta;
          pergunta.respostas = respostaObject;
        }
        delete pergunta.props;
        delete pergunta.config;
      });

      // Atrubui status de rascunho ou não ao formulário
      if (self.formularioRascunho) {
        form.rascunho = true
      } else {
        form.rascunho = false
      }

      // Prepare formulário to save
      if (this.isModeloForms) {
        self.formulario.modeloFormulario = { _id: self.formulario._id }
        delete self.formulario._id
      }
      if (self.domain && self.domainId) {
        self.formulario.domain = self.domain
        self.formulario.domainId = self.domainId
      }

      // Persiste o formulário completo
      await createUpdateForm(form, this.storageName)
        .then((response) => {
          if (!this.autoSaveUpdate) {
            self.setAlerta({
              tipoAlerta: "success",
              textAlerta: "Formulario Respondido com Sucesso! Obrigado!\nFechando formulario...",
              timeoutAlerta: 6500,
              identify: 'formulario_respondido_sucesso'
            })
          }
          self.formulario._id = response._id
          this.$emit("confirmSaveForms", response)
        })
        .catch(error => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro na atualizalção do formulário",
          })
          this.$emit("confirmSaveForms", error)
        })
    },
    valorPergunta(pPergunta, respostaPergunta) {
      this.respostasFormulario.set(`${pPergunta.ordem}`, {
        pergunta: pPergunta,
        resposta: respostaPergunta,
      });

      this.changeStatusAutoSaveUpdate()
    },
    changeStatusAutoSaveUpdate() {
      if (this.autoSaveUpdate) {
        const secondsTimeout = 5 * 1000
        const intervalFuncition = () => {
          this.saveUpdateForm()
        }

        if (!this.autoSaveUpdateTimeout) {
          this.autoSaveUpdateTimeout = setTimeout(intervalFuncition, secondsTimeout)
        } else {
          clearTimeout(this.autoSaveUpdateTimeout)
          this.autoSaveUpdateTimeout = setTimeout(intervalFuncition, secondsTimeout)
        }
      }
    },
    defineComponentInstance(nomeComponent) {
      return nomeComponent.replace("forms/", "").replace("audio/", "");
    },
    prepareFormulario(response){
      const self = this;
      self.formulario = JSON.parse(JSON.stringify(response.data))
      
      self.storageName = self.storageNameParam
      
      // Testa se o formulário tem perguntas com mídia
      const isMediaComponent = Array.from(self.formulario.perguntas).some(pergunta => {
          return ["VIDEO", "IMAGEM", "ARQUIVO", "AUDIO"].includes(pergunta.tipoPergunta.code)
        })
      
      // Caso tenha algum recurso de mídia no formulário, exige o storageName
      if (isMediaComponent && !(self.storageName && self.storageName.length > 3)){       
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Por favor, contate o Administrador.\n \nA unidade não possui storage configurado!",
          timeoutAlerta: 20000
        });
      } else {
        // Copia as perguntas e respostas originais para tratamento de exclusão dos arquivos do storage.
        self.perguntasOriginais = response.data.perguntas.map(p => ({...p}));
        self.perguntas = Object.assign([], response.data.perguntas).sort((a, b) => a.ordem - b.ordem);

        self.perguntas.forEach((p) => {
          // Tratamento para renderização dos componentes - transformação de respostas   
          switch(p.tipoPergunta.code) {
            case 'IMAGEM': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null} 
              break;
            case 'VIDEO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'ARQUIVO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'AUDIO':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'CURTA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'TEXTO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'VERDADEIRO_FALSO': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'DATA': 
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'UNICA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'MULTIPLA_ESCOLHA':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'EDITOR':
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
              break;
            case 'LEGENDA':
              p.config = {cols: 12, md: p.sizeMd, align: "start", offset: "0", valor: null}
              break;
            default:
              p.config = {cols: 12, md: p.sizeMd, align: "center", valor: null}
          }

          // Renderiza o componente de acordo com o código
          p.tipoPergunta.nomeComponent = self.defineComponentInstance(p.tipoPergunta.nomeComponent);

          // Tratamento para definição do modelo de exibição [Hard Code - Inicial]
          p.showSelectionBoxes = p.modeloExibicao == "CS" ? true : false

          // Tratamento para visualização de respostas quando já existirem
          if(p.respostas) {
            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null

            // Tratamento para renderização dos componentes - transformação de respostas       
            switch(p.tipoPergunta.code) {
              case 'IMAGEM': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'VIDEO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'ARQUIVO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'AUDIO':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, storageName: self.storageName, respostas: p.respostas}
                break;
              case 'CURTA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'TEXTO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'VERDADEIRO_FALSO': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'DATA': 
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, respostas: p.respostas}
                break;
              case 'UNICA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'MULTIPLA_ESCOLHA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'EDITOR':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              case 'LEGENDA':
                p.props = {labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes, respostas: p.respostas}
                break;
              default:
                p.props = {labelTitle: p.descricao}
            }

          } else {
            let perguntaValores = (p.perguntaValores && p.perguntaValores.length > 0) ? p.perguntaValores.sort((p1, p2) => {
              return p1.ordem - p2.ordem;
            }) : null
            p.props = { labelTitle: p.descricao, items: perguntaValores, obrigatorio: p.obrigatorio, showSelectionBoxes: p.showSelectionBoxes }
          }
        });
      }
    },
    async loadModel() {
      const self = this

      let response = {
        status: 200,
        message: "OK"
      }

      if (self.formularioParam) {
        if (!self.formularioParam.modeloFormulario) {
          self.isModeloForms = true
        }
        response = { ...response, data: self.formularioParam }
      } else {
        response = await self.$http.get(`/forms/${self.id}`).then(resp => {
          self.isModeloForms = false
          return resp
        }).catch(async () => {
          return await self.$http.get(`/modeloForms/${self.id}`).then(resp => {
            self.isModeloForms = true
            return resp
          }).catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Formulário / Modelo não encontrado",
            })
          })
        })
      }
      
      if (response.data) self.prepareFormulario(response)
    },
  },
  created() {
    this.loadModel();
  },
  beforeDestroy() {
    if (this.autoSaveUpdateTimeout) {
      clearTimeout(this.autoSaveUpdateTimeout)
    }
  },
};
</script>
