import Vue from 'vue'
import { getFileStorage, postFileStorage, deleteFileStorage } from './storageUtils';

const isTipoPerguntaMedia = (tipoPerguntaCodigo) => {
  return ["VIDEO", "IMAGEM", "ARQUIVO", "AUDIO"].indexOf(tipoPerguntaCodigo) !== -1
}

// Função recursiva para transformação de string json em objeto javascript
const jsonToObject = str => typeof str === "object" ? str : jsonToObject(JSON.parse(str))

// Função para persistir formulário
const saveUpdateForm = async (formularioToSave) => {
  if (formularioToSave && formularioToSave._id) {

    // Remove deleted files from storage
    // TODO: DEVERÁ SER DESENVOLVIDA A ROTINA DE EXCLUSÃO DE ARQUIVOS.
    let formGetted = await Vue.prototype.$http.get(`/forms/${formularioToSave._id}`).then(response => response.data)
    await cleanExcludedFormFiles(formularioToSave, formGetted)

    // Update formulario
    return await Vue.prototype.$http.put(`/forms/${formularioToSave._id}`, formularioToSave)
      .then(response => response.data)
  } else if (formularioToSave) {
    // Save formulario
    return await Vue.prototype.$http.post(`/forms`, formularioToSave)
      .then(response => response.data)
  }
}

async function createUpdateForm(formularioParam, storageName) {
  let formulario = Object.assign({}, formularioParam)

  if (formulario?.perguntas?.length > 0) {
    try {
      formulario.perguntas = await Promise.all(
        formulario.perguntas.map(async (pergunta) => {
          if (!pergunta.respostas?.length) return pergunta;
  
          // Filtra respostas válidas e processa uploads
          pergunta.respostas = (
            await Promise.all(
              pergunta.respostas.map(async (resposta) => {
                if (resposta === null) return null; // Ignora respostas nulas
  
                if (isTipoPerguntaMedia(pergunta.tipoPergunta.code) && !resposta._id) {
                  try {
                    const response = await postFileStorage(resposta, storageName);
                    return response.data; // Retorna resposta se sucesso
                  } catch (error) {
                    console.error("Erro ao enviar arquivo:", error);
                    return null; // Remove do vetor final
                  }
                }
  
                return resposta; // Retorna respostas que não precisam de upload
              })
            )
          ).filter((resposta) => resposta !== null); // Remove os que falharam
  
          return pergunta;
        })
      );
    } catch (error) {
      console.error("Erro ao processar o formulário:", error);
      throw error; // Opcional: interrompe a execução se necessário
    }
  }  

  // DESENVOLVER ROTINA PARA SALVAR O FORMULÁRIO JÁ PREPARADO PARA A BASE E COM ARQUIVOS CARREGADOS
  let formularioSaved = await saveUpdateForm(formulario)
    .then(response => {
      console.log("Formulario successfully updated")
      return response
    })
    .catch(error => {
      console.log("Formulario update error: ", error)
    })

  // RETORNA O FORMUÁRIO PERSISTIDO NA BASE
  return formularioSaved
}

async function cleanExcludedFormFiles(formAltered, formGetted) {
  console.log("Requesting cleanExcludedFormFiles", formAltered);

  let perguntasOriginaisParam = []
  let perguntasAlteradasParam = []

  // Tratamento para busca e resolução de arquivos originais e removidos
  if (formAltered && formAltered._id) {
    perguntasAlteradasParam = formAltered ? formAltered.perguntas : perguntasAlteradasParam
    perguntasOriginaisParam = formGetted ? formGetted.perguntas : perguntasOriginaisParam
  } else {
    return []
  }

  if (Array.isArray(perguntasOriginaisParam) && Array.isArray(perguntasAlteradasParam)) {

    let respostasAlteradas = []
    let respostasOriginais = []
    let respostasToDelete = []

    // Filtra somente as perguntas de mídia após alteração no formulário
    let perguntasAlteradas = perguntasAlteradasParam.map(p => ({ ...p }))
      .filter(p => (isTipoPerguntaMedia(p.tipoPergunta.code) && p.respostas != null && p.respostas.length > 0))

    // Filtra somente as perguntas de mídia antes alteração no formulário
    let perguntasOriginais = perguntasOriginaisParam.map(p => ({ ...p }))
      .filter(p => (isTipoPerguntaMedia(p.tipoPergunta.code) && p.respostas != null && p.respostas.length > 0))

    // Carrega as respostas resultantes das alterações realizadas no formulário
    if (perguntasAlteradas != null && perguntasAlteradas.length > 0) {
      respostasAlteradas = perguntasAlteradas.map(p => p.respostas).reduce((acc, val) => acc.concat(val), [])
    }

    // Carrega as respostas originais das alterações realizadas no formulário
    if (perguntasOriginais != null && perguntasOriginais.length > 0) {
      respostasOriginais = perguntasOriginais.map(p => p.respostas).reduce((acc, val) => acc.concat(val), [])
    }

    respostasToDelete = respostasOriginais.filter(r => r?._id).filter(r => !respostasAlteradas.map(r1 => r1._id).includes(r._id))

    return Promise.all(respostasToDelete.map(r => {
      return deleteFileStorage(r)
        .then(response => {
          console.log("Funcionou DELETE: \n", response);
          return response
        })
        .catch(error => {
          console.log("Não funcionou DELETE: \n", error);
          return error
        })
    })).then(response => {
      console.log("Arquivos deletados com sucesso")
      return response
    }).catch(error => {
      console.log("Não foi possível excluir um ou mais arquivos")
      return error
    })
  }
}

const removeAllFilesFromForms = async () => {
  // Modifica todos os formularios
  return await Vue.prototype.$http.get(`/forms?max=9999`).then(async response => {
    let originalForms = response.data.content

    return await Promise.all(originalForms
      .map(async originalForm => {
        return await Promise.all(originalForm.perguntas
          .filter(perg => isTipoPerguntaMedia(perg.tipoPergunta.code))
          .filter(perg => Array.isArray(perg.respostas) && perg.respostas.length > 0)
          .map(perg => perg.respostas).reduce((acc, val) => acc.concat(val), []).map(resposta => {

            // let storageName = originalForm.unidade && originalForm.unidade.storage ? originalForm.unidade.storage : "hc-teste"
            return resposta
          })
        )
      })
    ).then(response => {
      return response.filter(resp => resp.length > 0).reduce((acc, val) => acc.concat(val), []).map(resp => resp)
    }).then(response => {
      return response.map(resp => jsonToObject(resp))
    }).then(response => {
      response.map(resp => {
        if (resp.id) {
          getFileStorage(resp, "hc-teste").then(response0 => {
            console.log("Funcionou Get: \n", response0);
          }).catch(error0 => {
            console.log("Não funcionou Get: \n", error0);
          })

          deleteFileStorage("hc-teste", resp.nomeArquivo).then(response1 => {
            console.log("Funcionou DELETE: \n", response1);
          }).catch(error1 => {
            console.log("Não funcionou DELETE: \n", error1);
          })
        }
      })
      return response
    }).catch((error) => {
      console.error("Erro em removeAllFilesFromForms: ", error);
    })
  })
    .then(response => {
      console.log(response);
    })
}

async function getModeloFormulariosByTipoFormularioCodigo(tipoFormularioCodigo, unidadeId, tipoExameId) {

  if (!tipoFormularioCodigo) return

  let url = ""
  let tipoFormulario = await Vue.prototype.$http.get(`/tipoForms/findByCodigoEquals/${tipoFormularioCodigo}`).then(response => response.data).catch(() => null)

  if (tipoFormulario?._id) {
    url = `/modeloForms/findByTipoFormularioIdPermitidos/${tipoFormulario._id}`
    let param = `?`
    if (unidadeId) {
      url += `${param}unidadeId=${unidadeId}`
      param = `&`
    }
    if (tipoExameId) {
      url += `${param}tipoExameId=${tipoExameId}`
      param = `&`
    }
  } else {
    url = `/modeloForms?codigoTipoFormulario=${tipoFormularioCodigo}&situacao=true`
  }

  return await Vue.prototype.$http.get(url)
    .then(response => {
      let modelos = response?.data?.content || response?.data
      if (Array.isArray(modelos) && modelos.length > 0) {
        let modelosAtivos = modelos.filter(modelo => modelo.situacao)
        return modelosAtivos
      }
      return []
    })
}

export {
  createUpdateForm,
  cleanExcludedFormFiles,
  removeAllFilesFromForms,
  getModeloFormulariosByTipoFormularioCodigo,
}